<template>


  <nuxt-link class="dropdown-item" :class="showdefalut == menu.title ? 'show' : ''"
    :to="{ path: `${menu.path}`, query: menu.params }" v-if="menu.type === 'link'">{{
      menu.title }}
    <label class="menu-label" :class="menu.labelClass" v-if="menu.label">{{ menu.label }}</label>
  </nuxt-link>

 
  <ul class="dropdown-menu" v-if="menu.children && !menu.megaMenu" :class="showdefalut == menu.title ? 'show' : ''">
    <li class="nav-item" :class="child.children ? 'dropdown' : ''" v-for="(child, index) in menu?.children"
      :key="child.title + index">
      <HeaderElementsMenuList :menu="child" />
    </li>
  </ul>

</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useDataStore } from '~/store/data';
import { HandleRequest } from '~~/composables/HandleRequest';

let props = defineProps({
  menu: Object,
  show: Boolean,
})

let { Products, Blogs } = storeToRefs(useDataStore())
let products = ref([]);
let blogs = ref([])
onMounted(async () => {
  products.value = Products?.value?.data?.slice(0, 2);
  blogs.value = Blogs?.value?.data?.slice(0, 2);
});

let showdefalut = ref("")
function toggle(menu) {
  showdefalut.value = showdefalut.value != menu ? menu : ""
}
</script>

<style lang="scss" scoped></style>